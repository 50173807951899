(function (angular, _, undefined) {
    "use strict";
    var blog = angular.module("mikesBlog");

    blog.factory("BlogService", ["$http", "$q", "$localStorage", function ($http, $q, $localStorage) {
        return {
            "getList": function () {
                var def = $q.defer();
                if (typeof $localStorage.blog === "object") {
                    var last = _.last(Object.keys($localStorage.blog)
                        .sort(function (a, b) {
                            return parseInt(a) - parseInt(b);
                        })),
                        date = new Date(_.get($localStorage.blog, last + ".created") || 0);
                    $http.get("/api/blog/list?after=" + date.getTime())
                        .then(function (res) {
                            $localStorage.blog = _.uniqBy(
                                _.union(res.data, $localStorage.blog),
                                function (item) { return item._id; }
                            ).filter(function (item) { return !item.hidden; });
                            def.resolve();
                        })
                        .catch(function (err) {
                            def.reject(err);
                        });
                } else {
                    $http.get("/api/blog/list")
                        .then(function (res) {
                            $localStorage.blog = res.data.filter(function (item) { return !item.hidden; });
                            def.resolve();
                        })
                        .catch(function (err) {
                            def.reject(err);
                        });
                }
                return def.promise;
            },

            "getPost": function (id) {
                var def = $q.defer();
                if (typeof $localStorage.blog !== "object") {
                    $localStorage.blog = {};
                }
                if (_.has($localStorage.blog[id + ""], "body")) {
                    def.resolve();
                } else {
                    $http.get("/api/blog/title/" + id)
                        .then(function (res) {
                            $localStorage.blog[_.findIndex($localStorage.blog, { title: id })] = res.data;
                            def.resolve();
                        })
                        .catch(function (err) {
                            def.reject(err);
                        });
                }
                return def.promise;
            },

            "existing": function () {
                return typeof $localStorage.blog === "object";
            }
        };
    }]);

})(angular, _);
